<mat-dialog-content>
  <div class="content">
    <div class="header">
      <div class="title">{{ title }}</div>
      <div class="description">ここでは、詳細ページ（会社について）で表示される情報を登録します。</div>
    </div>
    <div class="spinner center" *ngIf="showSpinner">
      <mat-spinner></mat-spinner>
    </div>
    <form *ngIf="!showSpinner">
      <div>
        <div class="label">
          <img src="assets/notice/mark_blue.png" srcset="
            assets/notice/mark_blue.png    1x,
            assets/notice/mark_blue@2x.png 2x,
            assets/notice/mark_blue@3x.svg 3x
          " /> メイン画像<span class="normal"> {{ Constant.prefixFormatPngJpg }}</span>
        </div>
        <div class="image-container">
          <div *ngIf="mainImagePath == Constant.empty" class="noimage">
            <span>No Image</span>
          </div>
          <div class="image-back-area" *ngIf="mainImagePath != Constant.empty">
            <img class="image" [src]="mainImagePath" />
            <div class="image-back" [style.background-image]="'url(' + mainImagePath + ')'"></div>
          </div>
          <div class="buttons">
            <div mat-icon-button (click)="imgFileInput.click()">
              <img src="assets/joboffer/image_upload.png" srcset="
                  assets/joboffer/image_upload.png    1x,
                  assets/joboffer/image_upload@2x.png 2x,
                  assets/joboffer/image_upload@3x.svg 3x
                " />
              <input hidden type="file" #imgFileInput accept="image/png,image/jpeg" (change)="onChangeImage($event)" />
            </div>
            <div mat-icon-button (click)="onImageDelete()">
              <img src="assets/joboffer/image_delete.png" srcset="
                  assets/joboffer/image_delete.png    1x,
                  assets/joboffer/image_delete@2x.png 2x,
                  assets/joboffer/image_delete@3x.svg 3x
                " />
            </div>
          </div>
        </div>
      </div>
      <div class="descriptionarea">
        <div class="label">
          <img src="assets/notice/mark_blue.png" srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            " /> 本文
          <span class="required"> {{ Constant.prefixRequire }}</span>
          <sapn class="normal"> 画像にリンク設定はできません。</sapn>
        </div>
        <app-quill-common *ngIf="textData" [textData]="textData" (closeDialog)="onCloseDialog()" #quill></app-quill-common>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="center">
  <button mat-raised-button (click)="onCancel()">キャンセル</button>
  <button mat-raised-button (click)="onSave()"
  [disabled]="checkSaveBtn()" class="yes-btn">
    保存
  </button>
  <mat-spinner diameter="28" *ngIf="isSaving" class="spinner"></mat-spinner>
</mat-dialog-actions>
