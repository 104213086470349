import { AnalyticsResultDialogComponent } from './../dialog/analytics-result-dialog/analytics-result-dialog.component';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Auth } from '@aws-amplify/auth';
import { AuthService } from '../auth/auth.service';
import { Constant } from '../constant';
import { CommonUtil } from '../util/common-util';
import { MomentUtil } from '../util/moment-util';
import { ToastUtil } from '../util/toast-util';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.css']
})
export class AnalyticsComponent implements OnInit {
  public readonly Constant = Constant;
  public showSpinner = false;
  public form;
  public period = {start: null, end: null};
  public type_master;
  public unit_master;
  public contact_master;

  constructor(
    private title: Title,
    private auth: AuthService,
    private dialog: MatDialog,
    private commonUtil: CommonUtil,
    private momentUtil: MomentUtil,
    private toastUtil: ToastUtil,
  ) {
    this.title.setTitle(Constant.pageTitleAnalytics + Constant.pageTitleCommon);
  }

  ngOnInit(): void {
    // 初期設定取得
    this.getSetting();

    // 条件設定フォーム作成
    this.initForm();
  }

  // 実行
  onSearch() {
    if (!this.commonUtil.checkOnline()) {
      return;
    }

    // 集計単位
    let unit;
    if (this.form.value.unit === Constant.alTermUnitDayId) {
      unit = Constant.alTermUnitDay;
    } else if (this.form.value.unit === Constant.alTermUnitMonthId) {
      unit = Constant.alTermUnitMonth;
    } else {
      unit = Constant.alTermUnitYear;
    }

    const body = {
      search_type: this.form.value.type,
      term_start: this.momentUtil.getDateFormat(this.period.start),
      term_end: this.momentUtil.getDateFormat(this.period.end),
      term_unit: this.form.value.unit,
      term_diff: this.momentUtil.getDiff(this.period.start, this.period.end, unit) + 1,
    };

    this.dialog.open(AnalyticsResultDialogComponent, {
      width: Constant.analyticsDialogWidth,
      maxWidth: '90vw',
      height: Constant.analyticsDialogHeight,
      panelClass: 'analytics-dialog',
      autoFocus: false,
      data: {
        selectedType: this.form.value.type,
        selectedUnit: this.form.value.unit,
        typeMaster: this.type_master,
        body: body,
        period: this.period,
        auth: this.auth,
      }
    });
  }

  // 期間選択用
  getMaxDate(date) {
    return date ? date : new Date();
  }
  getMinDate(date) {
    return date ? date : new Date(2018, 0, 1);
  }

  // フォームバリデーション
  private initForm() {
    this.form = new FormGroup({
      type: new FormControl(Constant.empty, [Validators.required]),
      start: new FormControl(Constant.empty, [Validators.required]),
      end: new FormControl(Constant.empty, [Validators.required]),
      unit: new FormControl(Constant.empty, [Validators.required])
    });

  }

  // 初期設定取得
  private getSetting() {
    if (!this.commonUtil.checkOnline()) {
      return;
    }
    this.showSpinner = true;
    Auth.currentSession().then(session => {
      const apiPath = '/analytics/setting';
      const options = this.auth.createApiHeader(session);

      this.commonUtil
        .apiGet(apiPath, options)
        .then(res => {
          this.commonUtil.debug().log(res);
          this.unit_master = res.data.unit_master;
          this.type_master = res.data.type_master;
          this.setCondition(res.data.condition);
          this.showSpinner = false;
        })
        .catch(err => {
          this.showSpinner = false;
          this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
        });
    });
  }

  // 初期検索条件設定
  private setCondition(cond) {
    let type = Constant.alTypeTalent;
    if (cond.analytics_type) {
      type = cond.analytics_type;
    }
    this.form.patchValue({type: type});

    if (cond.analytics_start && cond.analytics_end) {
      this.period.start = cond.analytics_start;
      this.period.end = cond.analytics_end;
    } else {
      const start = new Date();
      start.setDate(start.getDate() - 6);
      this.period.start = start;
      this.period.end = new Date();
    }

    let unit = Constant.alTermUnitDayId;
    if (cond.analytics_unit) {
      unit = cond.analytics_unit;
    }
    this.form.patchValue({unit: unit});
  }
}
