import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastUtil } from './../../util/toast-util';
import { Constant } from './../../constant';

@Component({
  selector: 'app-pdfviewer-dialog',
  templateUrl: './pdfviewer-dialog.component.html',
  styleUrls: ['./pdfviewer-dialog.component.css']
})
export class PdfviewerDialogComponent implements OnInit {
  public showSpinner = true;
  public documentUrl;  // 会社説明資料PDFのURL
  public pdfPage = 1;  // PDF表示ページ
  public pdfTotalPage = 0;  // PDF全ページ数
  public tate = false;

  constructor(
    public dialogRef: MatDialogRef<PdfviewerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastUtil: ToastUtil,
  ) { }

  ngOnInit(): void {
    this.documentUrl = this.data.url;

    // 30秒経過し読み込みできないなら閉じる
    setTimeout(() => {
      this.pdfError();
    }, Constant.apiTimeoutSecond);
  }

  public pdfLoadComplete(pdfData) {
    this.pdfTotalPage = pdfData.numPages;

    // PDFの高さを合わせる
    setTimeout(() => {
      const canvas = document.querySelector('app-pdfviewer-dialog .canvasWrapper');
      if (!this.tate && canvas.clientHeight / canvas.clientWidth > 1) {
        // 縦長PDFなら再表示
        this.tate = true;
        return;
      }
      const viewer = document.querySelector('app-pdfviewer-dialog .pdf-viewer') as HTMLElement;
      if (viewer && canvas) {
        viewer.style.height = canvas.clientHeight + 'px';
      }
      const navi = document.querySelector('.cdk-overlay-pane.pdf-dialog') as HTMLElement;
      if (navi && canvas) {
        navi.style.width = canvas.clientWidth + 'px';
      }
    }, 200);
    this.showSpinner = false;
  }
  public　prevBtn() {
    this.pdfPage--;
  }
  public　nextBtn() {
    this.pdfPage++;
  }
  public pdfError() {
    if (this.pdfTotalPage === 0) {
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      this.dialogRef.close();
    }
  }
}
