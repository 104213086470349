<mat-dialog-content>
  <div class="content">
    <div class="header">
      <div class="title">{{ title }}</div>
      <div class="description">ここでは、様々なページで表示される企業概要を登録します。</div>
    </div>
    <div class="spinner center" *ngIf="showSpinner">
      <mat-spinner></mat-spinner>
    </div>
    <form [formGroup]="form" *ngIf="form">
      <div class="primage-container">
        <div class="label">
          <img src="assets/notice/mark_blue.png" srcset="
            assets/notice/mark_blue.png    1x,
            assets/notice/mark_blue@2x.png 2x,
            assets/notice/mark_blue@3x.svg 3x
          " /> トップ画像<span class="required"> ※必須</span><span class="normal"> {{ Constant.prefixFormatPngJpg }}</span>
        </div>
        <div class="image-container">
          <div *ngIf="companyPrImagePath == Constant.empty" class="noimage">
            <span>No Image</span>
          </div>
          <div class="image-back-area" *ngIf="companyPrImagePath != Constant.empty">
            <img class="primage" [src]="companyPrImagePath" />
            <div class="image-back" [style.background-image]="'url(' + companyPrImagePath + ')'"></div>
          </div>
          <div class="buttons">
            <div mat-icon-button (click)="prImgFileInput.click()">
              <img src="assets/joboffer/image_upload.png" srcset="
                  assets/joboffer/image_upload.png    1x,
                  assets/joboffer/image_upload@2x.png 2x,
                  assets/joboffer/image_upload@3x.svg 3x
                " />
              <input hidden type="file" #prImgFileInput accept="image/png,image/jpeg" (change)="onChangeImage($event, Constant.companyImagePrImage)" />
            </div>
            <div mat-icon-button (click)="onImageDelete(Constant.companyImagePrImage)">
              <img src="assets/joboffer/image_delete.png" srcset="
                  assets/joboffer/image_delete.png    1x,
                  assets/joboffer/image_delete@2x.png 2x,
                  assets/joboffer/image_delete@3x.svg 3x
                " />
            </div>
          </div>
        </div>
      </div>
      <!-- ロゴ -->
      <div class="companylogo-container">
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          /> 企業ロゴ<span class="required"> {{ Constant.prefixRequire }}</span><span class="normal"> {{ Constant.prefixFormatPngJpg
          }}{{ Constant.commma }}縦(高さ)に対して横(幅)が3倍以下の画像を推奨</span>
        </div>
        <div class="image-container">
          <div *ngIf="companyLogoPath == Constant.empty" class="noimage">
            <span>No Image</span>
          </div>
          <div class="logo-image" *ngIf="companyLogoPath != Constant.empty" >
            <img class="companylogo" [src]="companyLogoPath" />
          </div>
          <div class="buttons">
            <div mat-icon-button (click)="logoFileInput.click()">
              <img
                src="assets/joboffer/image_upload.png"
                srcset="
                  assets/joboffer/image_upload.png    1x,
                  assets/joboffer/image_upload@2x.png 2x,
                  assets/joboffer/image_upload@3x.svg 3x
                "
              />
              <input
                hidden
                type="file"
                #logoFileInput
                accept="image/png,image/jpeg"
                (change)="onChangeImage($event, Constant.companyImageCompanyLogo)"
              />
            </div>
            <div mat-icon-button (click)="onImageDelete(Constant.companyImageCompanyLogo)">
              <img
                src="assets/joboffer/image_delete.png"
                srcset="
                  assets/joboffer/image_delete.png    1x,
                  assets/joboffer/image_delete@2x.png 2x,
                  assets/joboffer/image_delete@3x.svg 3x
                "
              />
            </div>
          </div>
        </div>
      </div>
      <div class="flex half">
        <div class="input-container">
          <div class="label">
            <img src="assets/notice/mark_blue.png" srcset="
                assets/notice/mark_blue.png    1x,
                assets/notice/mark_blue@2x.png 2x,
                assets/notice/mark_blue@3x.svg 3x
              " /> 設立年月
          </div>
          <input
            class="input-text indent"
            formControlName="establishment"
            [maxlength]="maxLen20"
          />
        </div>
        <div class="input-container">
          <div class="label">
            <img src="assets/notice/mark_blue.png" srcset="
                assets/notice/mark_blue.png    1x,
                assets/notice/mark_blue@2x.png 2x,
                assets/notice/mark_blue@3x.svg 3x
              " /> 代表者名
          </div>
          <input
            class="input-text indent"
            formControlName="representative_name"
            [maxlength]="maxLen20"
          />
        </div>
        <div class="input-container">
          <div class="label">
            <img src="assets/notice/mark_blue.png" srcset="
                assets/notice/mark_blue.png    1x,
                assets/notice/mark_blue@2x.png 2x,
                assets/notice/mark_blue@3x.svg 3x
              " /> 資本金
          </div>
          <input
            class="input-text indent"
            formControlName="capital"
            [maxlength]="maxLen20"
          /> 円
        </div>
      </div>
      <div class="flex half">
        <div class="input-container">
          <div class="label">
            <img src="assets/notice/mark_blue.png" srcset="
                assets/notice/mark_blue.png    1x,
                assets/notice/mark_blue@2x.png 2x,
                assets/notice/mark_blue@3x.svg 3x
              " /> 従業員数
          </div>
          <input
            class="input-text indent"
            formControlName="employees"
            [maxlength]="maxLen20"
          /> 人
        </div>
        <div class="input-container address">
          <div class="label">
            <img src="assets/notice/mark_blue.png" srcset="
                assets/notice/mark_blue.png    1x,
                assets/notice/mark_blue@2x.png 2x,
                assets/notice/mark_blue@3x.svg 3x
              " /> 本社（所在地）
          </div>
          <input
            class="input-text indent"
            formControlName="address"
            [maxlength]="maxLenAddress"
          />
        </div>
      </div>
      <!-- 勤務地(採用エリア) -->
      <div class="input-container">
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          /> 勤務地<span class="required"> {{ Constant.prefixRequire }}</span>
          <span class="normal"> {{ Constant.prefixMultiSelectEnable }}</span>
        </div>
        <button
          type="button"
          mat-raised-button
          (click)="onMultipleSelect(Constant.companyInfoEmploymentAreaType)"
          class="select-btn indent"
        >
          選択
        </button>
        <div class="selected-view indent">
          <ng-container *ngFor="let item of employmentAreaAry; last as last">
            <span>{{ item }}<ng-container *ngIf="!last">、</ng-container></span>
          </ng-container>
        </div>
      </div>
      <!-- 業種 -->
      <div class="input-container">
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          /> 業種<span class="required"> {{ Constant.prefixRequire }}</span>
          <span class="normal"> {{ Constant.prefixMultiSelectEnable }}</span>
        </div>
        <button
          type="button"
          mat-raised-button
          (click)="onMultipleSelect(Constant.companyInfoIndustryType)"
          class="select-btn indent"
        >
          選択
        </button>
        <div class="selected-view indent">
          <ng-container *ngFor="let item of industryAry; last as last">
            <span>{{ item }}<ng-container *ngIf="!last">、</ng-container></span>
          </ng-container>
        </div>
      </div>
      <!-- 外部リンク -->
      <div class="input-container">
        <div class="label">
          <img src="assets/notice/mark_blue.png" srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            " /> 外部リンク
        </div>
        <div class="link flex">
          <div class="col1">コーポレートサイト</div>
          <div class="col2">
            <input class="input-text" placeholder="URL"
            formControlName="homepage"
            [maxlength]="maxLenLink">
          </div>
        </div>
        <div class="link flex">
          <div class="col1">X</div>
          <div class="col2">
            <input class="input-text" placeholder="URL"
            formControlName="link_x"
            [maxlength]="maxLenLink">
          </div>
        </div>
        <div class="link flex">
          <div class="col1">Facebook</div>
          <div class="col2">
            <input class="input-text" placeholder="URL"
            formControlName="link_fb"
            [maxlength]="maxLenLink">
          </div>
        </div>
        <div class="link flex">
          <div class="col1">Instagram</div>
          <div class="col2">
            <input class="input-text" placeholder="URL"
            formControlName="link_ig"
            [maxlength]="maxLenLink">
          </div>
        </div>
        <div class="link flex">
          <div class="col1">TikTok</div>
          <div class="col2">
            <input class="input-text" placeholder="URL"
            formControlName="link_tt"
            [maxlength]="maxLenLink">
          </div>
        </div>
        <div class="link flex">
          <div class="col1">LinkedIn</div>
          <div class="col2">
            <input class="input-text" placeholder="URL"
            formControlName="link_linkedin"
            [maxlength]="maxLenLink">
          </div>
        </div>
        <div class="link flex">
          <div class="col1">note</div>
          <div class="col2">
            <input class="input-text" placeholder="URL"
            formControlName="link_note"
            [maxlength]="maxLenLink">
          </div>
        </div>
        <div class="link flex">
          <div class="col1">
            <input class="input-text" placeholder="名称"
            formControlName="link_name1"
            [maxlength]="maxLen20">
          </div>
          <div class="col2">
            <input class="input-text" placeholder="URL"
            formControlName="link_other1"
            [maxlength]="maxLenLink">
          </div>
        </div>
        <div class="link flex">
          <div class="col1">
            <input class="input-text" placeholder="名称"
            formControlName="link_name2"
            [maxlength]="maxLen20">
          </div>
          <div class="col2">
            <input class="input-text" placeholder="URL"
            formControlName="link_other2"
            [maxlength]="maxLenLink">
          </div>
        </div>
        <div class="link flex">
          <div class="col1">
            <input class="input-text" placeholder="名称"
            formControlName="link_name3"
            [maxlength]="maxLen20">
          </div>
          <div class="col2">
            <input class="input-text" placeholder="URL"
            formControlName="link_other3"
            [maxlength]="maxLenLink">
          </div>
        </div>
        <mat-error *ngIf="otherLinkError" class="indent error">名称とURLは両方入力してください。</mat-error>
      </div>
      <!-- サマリー -->
      <div class="input-container">
        <div class="label">
          <img src="assets/notice/mark_blue.png" srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            " /> 企業情報サマリー
        </div>
        <div class="desc indent">
          Google検索結果や<a [href]="Constant.moreCompanyListUrl" target="_blank">CaLinのタレント集客ページ</a>等に表示する企業情報サマリーを登録してください。<br>
          ※登録が無い場合、CaLinのタレント集客ページ等に企業情報が掲載されません。
        </div>
        <div class="label-count" *ngIf="summary" [class.length-red]="summary.value.length>maxLenSummary">{{ summary.value.length }}/{{ maxLenSummary }}</div>
        <textarea
          class="input-textarea indent"
          formControlName="summary"
          rows="3" #summary
          (input)="onInput()"
          (keydown.enter)="onKeyDown($event)"></textarea>
      </div>


    </form>
  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="center">
  <button mat-raised-button (click)="onCancel()">キャンセル</button>
  <button mat-raised-button (click)="onSave()"
  [disabled]="checkSaveBtn()" class="yes-btn">
    保存
  </button>
  <mat-spinner diameter="28" *ngIf="isSaving" class="spinner"></mat-spinner>
</mat-dialog-actions>
