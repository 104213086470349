import { Component, OnInit, HostListener } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { AuthService } from './../auth/auth.service';
import { Constant } from './../constant';
import { Title } from '@angular/platform-browser';
import { CommonUtil } from '../util/common-util';
import { ToastUtil } from '../util/toast-util';
import { MatDialog } from '@angular/material/dialog';
import { JobOfferBaseDialogComponent } from './../dialog/joboffer-base-dialog/joboffer-base-dialog.component';
import { JobofferEditDialogComponent } from './../dialog/joboffer-edit-dialog/joboffer-edit-dialog.component';
import { JobofferDetailDialogComponent } from './../dialog/joboffer-detail-dialog/joboffer-detail-dialog.component';
import { GeneralYesNoDialogComponent } from './../dialog/general-yes-no-dialog/general-yes-no-dialog.component';
import { GeneralMessageDialogComponent } from './../dialog/general-message-dialog/general-message-dialog.component';
import { TalentListCondition } from './../model/talent-list-condition';
import { JobofferPriorityDialogComponent } from '../dialog/joboffer-priority-dialog/joboffer-priority-dialog.component';

@Component({
  selector: 'app-job-offer',
  templateUrl: './job-offer.component.html',
  styleUrls: ['./job-offer.component.css']
})
export class JobOfferComponent implements OnInit {
  public tellNo = Constant.calinTellNo + Constant.calinWorkTime;
  public supportMail = Constant.calinSupportMail;
  public baseInfo;
  public jobOffers;
  public jobOfferMaster = {};
  public companyMasters = {};
  public apiPath = '/joboffer';
  public companyInfoApiPath = '/company';
  public readonly Constant = Constant;
  public showSpinner = true;

  private image_url = '';
  private isJobOfferGet = false;
  private isjobOfferMasterGet = false;
  private email = '';

  constructor(
    private title: Title,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
    private dialog: MatDialog,
    private auth: AuthService,
  ) {
    this.title.setTitle(Constant.pageTitleJobOffer + Constant.pageTitleCommon);
    this.email = localStorage.getItem(Constant.lsOperator);
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    this.commonUtil.checkRefreshToken(this.auth);
  }

  ngOnInit() {
    window.scroll(0, 0);
    if (!this.commonUtil.isOnline()) {
      this.showErrorToast(Constant.msgNetworkError);
      this.showSpinner = false;
      return;
    }

    this.getOfferData();
  }

  // 編集・新規作成
  onDetail(index) {
    if (!this.commonUtil.checkOnline()) {
      return;
    }
    const dialogRef = this.dialog.open(JobofferDetailDialogComponent, {
      width: Constant.jobOfferDialogWidth,
      height: Constant.jobOfferDialogHeight,
      autoFocus: false,
      data: {
        index: index,
        id: this.jobOffers[index].id,
        jobOfferMaster: this.jobOfferMaster,
        jobOffer: this.jobOffers[index]
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        if (result.isEdit) {
          // 編集画面を表示する
          this.onEdit(result.index);
        } else if (result.isBase) {
          this.onBaseInfo();
        } else if (result.isReload) {
          // 再読み込み
          this.getOfferData()
        } else {
          // ステータス更新
          this.releaseExec(result.index, result.isRelease);
        }
      }
    });
  }

  // 詳細情報表示
  onEdit(index?) {
    const elm = <HTMLElement>document.activeElement;
    elm.blur();

    if (!this.commonUtil.isOnline()) {
      this.showErrorToast(Constant.msgNetworkError);
      return;
    }

    let jobOffer;
    let type;
    let jobOfferId = 0; // TODO:編集ダイアログ内で最新を取得するため、IDだけ渡す
    if (index != null) {
      jobOffer = this.jobOffers[index];
      jobOfferId = this.jobOffers[index].id
      type = Constant.joTypeEdit;
    } else {
      jobOffer = {
        id: null,
        image_url: '',
        image_path: '',
        title: '',
        employment_type: '',
        job_category_id: '',
        job_category_middle_id: '',
        job_place_id: '',
        job_place_city: '',
        sub_job_place_id: '',
      };
      type = Constant.joTypeAdd;
      this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionJobOfferNew);
    }

    const dialogRef = this.dialog.open(JobofferEditDialogComponent, {
      width: Constant.jobOfferDialogWidth,
      height: Constant.jobOfferDialogHeight,
      autoFocus: false,
      disableClose: true,
      data: {
        type: type,
        id: jobOfferId,
        master: this.jobOfferMaster,
        jobOffer: jobOffer,
        jobCategoryMaster: this.jobOfferMaster[Constant.joJobType],
        jobPlaceMaster: this.jobOfferMaster[Constant.joJobPlaceType],
        employmentTypeMaster: this.jobOfferMaster[Constant.joEmploymentType],
        email: this.email
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== Constant.empty) {
        this.getOfferData();
      }
    });
  }

  // 基本情報登録
  onBaseInfo() {
    const elm = <HTMLElement>document.activeElement;
    elm.blur();

    // GAイベント
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionJobOfferCommonInfo);
    if (!this.commonUtil.isOnline()) {
      this.showErrorToast(Constant.msgNetworkError);
      return;
    }
    const dialogRef = this.dialog.open(JobOfferBaseDialogComponent, {
      width: Constant.jobOfferDialogWidth,
      height: Constant.jobOfferDialogHeight,
      autoFocus: false,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getOfferData();
      }
    });
  }

  //優先表示
  onPriority() {
    const elm = <HTMLElement>document.activeElement;
    elm.blur();

    if (!this.commonUtil.checkOnline()) {
      return;
    }

    const dialogRef = this.dialog.open(JobofferPriorityDialogComponent, {
      width: '630px',
      height: Constant.tagEditDialogHeight,
      autoFocus: false,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.getOfferData();
      }
    });
  }

  onRelease(index, isRelease) {
    let message = {};
    if (isRelease) {
      message = Constant.joMsgComfirmRelease;
    } else {
      message = Constant.joMsgComfirmStop;
    }
    const dialogRef = this.dialog.open(GeneralYesNoDialogComponent, {
      width: Constant.dialogWidth,
      autoFocus: false,
      data: { msg: message }
    });
    dialogRef.afterClosed().subscribe(isOK => {
      if (isOK) {
        this.releaseExec(index, isRelease);
      }
    });
  }

  // 削除
  onDel(index) {
    if (!this.commonUtil.isOnline()) {
      this.showErrorToast(Constant.msgNetworkError);
      return;
    }

    if (this.jobOffers[index].apply_cnt === 0 && this.jobOffers[index].mind_cnt === 0) {
      // 気になるや応募が0なら削除可能
      const dialogRef = this.dialog.open(GeneralYesNoDialogComponent, {
        width: Constant.dialogWidth,
        autoFocus: false,
        data: { msg: Constant.joMsgComfirmDel }
      });
      dialogRef.afterClosed().subscribe(isOK => {
        if (isOK) {
          // 削除のAPI呼び出し
          this.delJobOffer(this.jobOffers[index].id);
        }
      });

    } else {
      // 気になるや応募があれば削除不可
      this.dialog.open(GeneralMessageDialogComponent, {
        width: Constant.dialogWidth,
        autoFocus: false,
        data: Constant.joMsgComfirmDelCant
      });
    }
  }

  // コピー
  onJobOfferCopy(index) {
    const dialogRef = this.dialog.open(GeneralYesNoDialogComponent, {
      width: Constant.dialogWidth,
      autoFocus: false,
      data: { msg: Constant.joMsgComfirmCopy }
    });
    dialogRef.afterClosed().subscribe(isOK => {
      if (isOK) {
        // コピーのAPI呼び出し
        this.copyJobOffer(this.jobOffers[index].id);
      }
    });
  }

  // 公開ステータス変更
  private releaseExec(index, isRelease) {
    if (!this.commonUtil.isOnline()) {
      this.showErrorToast(Constant.msgNetworkError);
      return;
    }
    this.showSpinner = true;
    Auth.currentSession().then(session => {
      const apiPath = this.apiPath + '/status';
      const options = this.auth.createApiHeader(session);
      let toastMessage = '';
      options['body'] = {
        id: this.jobOffers[index].id,
        email: this.email
      };
      if (isRelease) {
        options['body']['release_status'] = Constant.joReleaseStatusRelease;
        toastMessage = Constant.msgStatusRelease;
      } else {
        options['body']['release_status'] = Constant.joReleaseStatusStop;
        toastMessage = Constant.msgStatusStop;
      }
      this.commonUtil
        .apiPut(apiPath, options)
        .then(res => {
          this.showToast(toastMessage);
          this.getOfferData();
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
          if (err.status === Constant.NG) {
            if (err.data.type === 1) {
              // 必須項目なしor文字数超過
              this.showErrorToast(Constant.msgNoticeStatusFailedContents);
            } else {
              this.showErrorToast(Constant.msgNoticeStatusFailed);
            }
          } else {
            this.showErrorToast(Constant.msgNoticeStatusFailedRetry);
          }
          this.getOfferData();
        });
    });
  }

  // 閲覧、気になる、応募のリンクタップ
  onToList(action, index) {
    const condition: TalentListCondition[] = [];
    condition[0] = {
      type: Constant.offerTitleType,
      id: this.jobOffers[index].id
    };
    condition[1] = {
      type: Constant.offerActionType,
      id: action
    };
    if (action === Constant.offerActionView) {
      // 閲覧数の場合、気になると応募も選択する
      condition[2] = {
        type: Constant.offerActionType,
        id: Constant.offerActionMind
      };
      condition[3] = {
        type: Constant.offerActionType,
        id: Constant.offerActionApply
      };
    }
    localStorage.setItem(Constant.lsTalentListCondition, JSON.stringify(condition));
    window.open('list');
  }

  // 求人情報を取得する
  private getOfferData() {
    if (!this.commonUtil.isOnline()) {
      this.showSpinner = false;
      return;
    }
    // ぐるぐる表示
    this.showSpinner = true;
    this.jobOffers = null;
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      this.commonUtil
        .apiGet(this.apiPath, options)
        .then(res => {
          this.jobOffers = res.data;
          this.isJobOfferGet = true;
          this.getJobofferImageUrl(options);
          this.getJobOfferMaster(options);
          this.commonUtil.debug().log(this.jobOffers);
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
          this.showSpinner = false;
          this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
        });
    });
  }

  // 求人情報ごとの画像のURLを取得する
  private getJobofferImageUrl(options) {
    const urlApiPath = this.apiPath + '/url';
    const ids = [];
    for (const joboffer of this.jobOffers) {
      ids.push(joboffer.id);
    }
    options['body'] = {
      ids: ids
    };

    this.commonUtil
      .apiPost(urlApiPath, options)
      .then(res => {
        for (const joboffer of this.jobOffers) {
          const urldata = res.data.filter(function(data) {
            return joboffer.id === data.id;
          });
          joboffer.image_url = urldata[0].url;
        }
      })
      .catch(err => {
        this.commonUtil.debug().log(err);
      });
  }

  // 削除のAPI呼び出し
  private delJobOffer(index) {
    if (!this.commonUtil.isOnline()) {
      this.showErrorToast(Constant.msgNetworkError);
      return;
    }
    this.showSpinner = true;
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      options['body'] = {
        joboffer_id: index
      };

      this.commonUtil
        .apiDel(this.apiPath, options)
        .then(res => {
          this.showToast(Constant.msgNoticeDel);
          this.getOfferData();
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
          this.showErrorToast(Constant.msgNoticeDelFailed2);
          this.getOfferData();
        });
    });
  }

  // 求人情報コピーのAPI呼び出し
  private copyJobOffer(index) {
    if (!this.commonUtil.isOnline()) {
      this.showErrorToast(Constant.msgNetworkError);
      return;
    }

    this.showSpinner = true;
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      options['body'] = {
        joboffer_id: index,
        email: this.email
      };

      this.commonUtil
        .apiPost(this.apiPath + '/copy', options)
        .then(res => {
          this.showToast(Constant.msgCopySuccess);
          this.getOfferData();
        })
        .catch(err => {
          if (err.status === Constant.NG) {
            this.showErrorToast(Constant.msgCopyError);
          } else {
            this.showErrorToast(Constant.msgCopyErrorRetry);
          }
          this.getOfferData();
        });
    });
  }

  // トースト表示
  private showToast(msg) {
    this.toastUtil.clearAllShowingToast();
    this.toastUtil.showInformationToast('', msg, Constant.toastShowMiliSec);
  }

  // エラートースト表示
  private showErrorToast(msg) {
    this.toastUtil.clearAllShowingToast();
    this.toastUtil.showErrorToast('', msg, Constant.toastShowMiliSec);
  }

  // 求人情報関連のマスター情報を取得する
  private getJobOfferMaster(options) {
    // 一度取得したら取得しない
    if (Object.keys(this.jobOfferMaster).length === 0) {
      this.commonUtil
        .apiGet(this.apiPath + '/master', options)
        .then(res => {
          res.data.forEach(element => {
            this.jobOfferMaster[element.type] = element.data;
          });
          this.isjobOfferMasterGet = true;
          this.convertTextJobofferMaster();
          this.judgeHideSpinnerView();
          this.commonUtil.debug().log(this.jobOfferMaster);
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
        });
    } else {
      this.showSpinner = false;
      this.convertTextJobofferMaster();
    }
  }

  // 職種カテゴリー・勤務地・雇用形態をテキストに変換する
  private convertTextJobofferMaster() {
    for (const jobOffer of this.jobOffers) {
      // 職種カテゴリー
      for (const jobCategory of this.jobOfferMaster[Constant.joJobType]) {
        if (jobOffer.job_category_id === jobCategory.large_id) {
          jobOffer.job_category_text = jobCategory.large_item_value;
          const categoryMiddles = this.jobOfferMaster[Constant.joJobType].filter(function(data) {
            return data.large_id === jobCategory.large_id;
          });
          for (const categoryMiddle of categoryMiddles) {
            if (jobOffer.job_category_middle_id === categoryMiddle.id) {
              jobOffer.job_category_middle_text = categoryMiddle.item_value;
              break;
            }
          }
        }
      }
      // 勤務地
      for (const jobPlace of this.jobOfferMaster[Constant.joJobPlaceType]) {
        if (jobOffer.job_place_id === jobPlace.id) {
          jobOffer.job_place_text = jobPlace.item_value;
          break;
        }
      }
      // 勤務地（追加）
      const subJobPlaceIds = jobOffer.sub_job_place_id.split(',');
      const targetTexts = this.commonUtil.convertMasterIdToItemValue(
        subJobPlaceIds,
        this.jobOfferMaster[Constant.joJobPlaceType]
      );
      jobOffer.sub_job_place_ary = targetTexts;

      // 雇用形態
      let employmentText = '';
      const employmentTypeAry = jobOffer.employment_type.split(',');
      employmentTypeAry.forEach(id => {
        const selectedData = this.jobOfferMaster[Constant.joEmploymentType].filter(function(data) {
          return data.id === Number(id);
        });
        if (selectedData.length > 0) {
          if (employmentText !== '') {
            employmentText = employmentText + '、';
          }
          employmentText = employmentText + selectedData[0].item_value;
        }
      });
      jobOffer.employment_type = Number(employmentTypeAry[0]);
      jobOffer.employment_text = employmentText;
    }
  }

  private judgeHideSpinnerView() {
    if (
      this.isJobOfferGet &&
      this.isjobOfferMasterGet
    ) {
      this.showSpinner = false;
    }
  }

  // 求人情報詳細URLをコピー
  onCopy(index: string) {
    let element: HTMLElement;
    element = document.getElementById('url-code-' + index);

    if (element) {
      const temp = document.createElement('div');
      temp.appendChild(document.createElement('span')).textContent = element['value'];
      document.body.appendChild(temp);
      document.getSelection().selectAllChildren(temp);
      const succeeded = document.execCommand('copy');

      if (succeeded) {
        // コピー成功
        this.toastUtil.clearAllShowingToast();
        this.toastUtil.showInformationToast('', Constant.msgCopySuccess, Constant.toastShowMiliSec);
      } else {
        // コピー失敗
        this.toastUtil.clearAllShowingToast();
        this.toastUtil.showErrorToast('', Constant.msgCopyError, Constant.toastShowMiliSec);
      }
      document.body.removeChild(temp);
    }
  }
}
