import { TalentListPagingCondition } from './../model/TalentListPagingCondition';
import { Component, OnInit, HostListener, ViewChild, AfterViewInit } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { AuthService } from './../auth/auth.service';
import { Constant } from './../constant';
import { FormGroup } from '@angular/forms';
import { PaginationInstance } from 'ngx-pagination';
import { ToastUtil } from '../util/toast-util';
import { CommonUtil } from './../util/common-util';
import { Title } from '@angular/platform-browser';
import { OnBeforeunload } from './../auth/auth.guard';
import { MatDialog } from '@angular/material/dialog';
import { TalenttagEditDialogComponent } from './../dialog/talenttag-edit-dialog/talenttag-edit-dialog.component';
import { SearchContainerComponent } from './../search-container/search-container.component';
import { TalentEditDialogComponent } from '../dialog/talent-edit-dialog/talent-edit-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-talent-list',
  templateUrl: './talent-list.component.html',
  styleUrls: ['./talent-list.component.css'],
  providers: [Title]
})
export class TalentListComponent implements OnInit, OnBeforeunload, AfterViewInit {
  public condition = [];
  public pagingReqParam: TalentListPagingCondition;
  public conditions;
  public headers;
  public talents = null;
  public paging;
  public form: FormGroup;
  public readonly Constant = Constant;
  public showSpinner = true;
  public searchContainerData;
  private isSearching = false;
  public currentOffset: number;
  public config: PaginationInstance = {
    id: 'talents',
    itemsPerPage: 1,
    currentPage: 1,
    totalItems: 0
  };
  public talentData; // タレントリストテーブル用データ
  private profileMaster = {};

  @ViewChild(SearchContainerComponent)
  protected searchComponent: SearchContainerComponent;

  constructor(
    private router: Router,
    private auth: AuthService,
    private toastUtil: ToastUtil,
    private commonUtil: CommonUtil,
    private title: Title,
    private dialog: MatDialog,
  ) {
    this.title.setTitle(Constant.pageTitleTalentList + Constant.pageTitleCommon);
  }

  @HostListener('window:beforeunload', ['$event'])
  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    this.commonUtil.checkRefreshToken(this.auth);
  }

  ngOnInit() {
    // 画面表示時に一番上に持っていく
    window.scroll(0, 0);

    // ネットワークチェック
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      this.showSpinner = false;
      return;
    }

    if (localStorage.getItem(Constant.lsTalentListCondition)) {
      this.condition = JSON.parse(localStorage.getItem(Constant.lsTalentListCondition));
    }

    this.currentOffset = 0;
    this.config.itemsPerPage = 10;

    // ページング
    this.pagingReqParam = {
      offset: 0,
      limit: this.config.itemsPerPage
    };

    // 検索コンポーネント用
    this.searchContainerData = {
      condition: this.condition,
      conditions: this.conditions,
      msgExceptFlg: false
    };

    // タレントリストテーブル表示用
    this.talentData = {
      talents: this.talents,
      pagingConfig: this.config,
      checkFlg: false,
      listFlg: true
    };
  }

  ngAfterViewInit() {
    // タレントカルテ作成用マスタ取得
    this.getProfileMaster();
  }

  shouldDeleteLocalStorageBeforeunload() {
    // 画面を離れる時に検索条件の保存内容をクリアする
    localStorage.removeItem(Constant.lsTalentListCondition);
  }

　// 検索コンポーネントより検索ボタン押下
  searchTalent(cond) {
    if (cond === true) {
      this.showSpinner = true;
    } else if (cond === false) {
      this.showSpinner = false;
    } else {
      this.condition = cond;
      this.onSearch();
    }
  }

  onSearch() {
    this.showSpinner = true;
    // GAイベント
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionSearch);

    // ページング
    this.pagingReqParam = {
      offset: 0,
      limit: this.config.itemsPerPage
    };
    // 検索条件保存
    localStorage.setItem(Constant.lsTalentListCondition, JSON.stringify(this.condition));

    // 検索
    this.getList();
  }

  // ページングのボタンがクリックされた時の処理
  getSelectedPageData(selectedPage: number) {
    // ネットワークチェック
    if (!this.commonUtil.checkOnline()) {
      return;
    }

    this.showSpinner = true;
    window.scroll(0, 0);
    this.currentOffset = (selectedPage - 1) * this.config.itemsPerPage;
    this.config.currentPage = selectedPage;
    // ページングでデータを取得する場合は現在の検索条件はそのまま使用したいので
    // onSearch()は通さず、ページングの条件だけ更新してgetList()を呼ぶ
    this.pagingReqParam = {
      offset: this.currentOffset,
      limit: this.config.itemsPerPage
    };
    this.getList();
  }

  /* 管理タグ登録 */
  onTagRegist() {
    if (!this.commonUtil.checkOnline()) {
      return;
    }

    const dialogRef = this.dialog.open(TalenttagEditDialogComponent, {
      width: Constant.tagEditDialogWidth,
      height: Constant.tagEditDialogHeight,
      autoFocus: false,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        // 検索条件再読み込み
        this.searchComponent.resetHeaderInfo();
      }
    });
  }

  /* タレント新規作成 */
  onEditTalent() {
    const elm = <HTMLElement>document.activeElement;
    elm.blur();

    if (!this.commonUtil.checkOnline()) {
      return;
    }

    if (Object.keys(this.profileMaster).length === 0) {
      return;
    }

    const dialogRef = this.dialog.open(TalentEditDialogComponent, {
      width: Constant.multiSelectDialogWidth,
      height: Constant.jobOfferDialogHeight,
      autoFocus: false,
      disableClose: true,
      panelClass: 'talentedit-dialog',
      data: {mode: Constant.csvTalentTypeAdd, talentId: null, master: this.profileMaster}
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        // this.showSpinner = true;
        // 検索条件をクリアして再検索
        this.searchComponent.onSearchConditionClear();
        this.searchComponent.resetHeaderInfo();
      }
    });
  }

  /* CSV取込 */
  onCsvTalent() {
    if (!this.commonUtil.checkOnline()) {
      return;
    }

    this.router.navigate(['/' + Constant.rtImportTalent]);
  }


  /*
   * プライベート関数
   */
  private getList(): void {
    // ネットワークチェック
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      this.showSpinner = false;
      this.isSearching = false;
      return;
    }
    if (this.isSearching) {
      return;
    }
    this.isSearching = true;
    Auth.currentSession().then(session => {
      const apiPath = '/talentlist';
      const options = this.auth.createApiHeader(session);
      if (!this.condition) {
        this.condition = [];
      }
      options['body'] = {
        Conditions: this.condition,
        paging: this.pagingReqParam
      };

      this.commonUtil.debug().log(options);
      this.commonUtil
        .apiPost(apiPath, options)
        .then(res => {
          this.commonUtil.debug().log(res.data);
          this.getListExec(res, options);
          this.isSearching = false;
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
          this.showSpinner = false;
          this.isSearching = false;
          if (this.commonUtil.apiNetworkError(err.message)) {
            this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
          }
        });
    });
  }

  // ページング用のデータを設定する
  private setConfigData(data: any) {
    this.config.totalItems = data.data.paging.total;
    this.config.currentPage = data.data.paging.offset / data.data.paging.limit + 1;
    this.config.itemsPerPage = data.data.paging.limit;
    this.talentData.pagingConfig = this.config;
  }


  // リストAPI取得後の処理
  private getListExec(data, options) {
    window.scroll(0, 0);
    this.talents = data.data.list;
    this.talentData.talents = this.talents;
    this.showSpinner = false;
    this.conditions = data.data.Conditions;
    this.searchContainerData.conditions = this.conditions;
    this.setConfigData(data);
    if (this.talents) {
      // 各タレントの画像URLを取得する
      this.commonUtil.getTalentImg(this.talents, options);
    }
  }

  private postTagMaster(sendData) {
    Auth.currentSession().then(session => {
      const apiPath = '/talenttag/master';
      const options = this.auth.createApiHeader(session);
      options['body'] = {tags: sendData};

      this.commonUtil.apiPost(apiPath, options).then(res => {
        // 検索条件再読み込み
        this.searchComponent.resetHeaderInfo();

        // 成功トースト表示
        this.toastUtil.showInformationToast('', Constant.msgResultSaveSuccess, Constant.toastShowMiliSec);
      })
      .catch(err => {
        this.showSpinner = false;
        this.toastUtil.clearAllShowingToast();
        // 保存失敗エラー
        if (err.message === Constant.msgResultSaveFailed) {
          this.toastUtil.showErrorToast('', Constant.msgResultSaveFailed, Constant.toastShowMiliSec);
          // タレントリストを読み込む
          this.searchComponent.resetHeaderInfo();
        } else {
          this.toastUtil.showErrorToast('', Constant.msgSaveFailedRetry, Constant.toastShowMiliSec);
        }
      });
    });
  }

  // タレントカルテ登録用マスタ取得
  private getProfileMaster() {
    Auth.currentSession().then(session => {
      const apiPath = '/talent/master';
      const options = this.auth.createApiHeader(session);

      this.commonUtil.apiGet(apiPath, options).then(res => {
        res.data.forEach(element => {
          this.profileMaster[element.type] = element.data;
        });
      })
      .catch(err => {
      });
    });
  }
}
