<div class="header">
  <ng-container *ngIf="!showSpinner">
    <div class="header-left" *ngIf="prContent.release_status == Constant.pcReleaseStatusNoRelease">
      <div class="release_status default">
        未公開
      </div>
    </div>
    <div class="header-left" *ngIf="prContent.release_status == Constant.pcReleaseStatusRelease">
      <div class="release_status released">
        公開中
      </div>
    </div>
    <div class="header-left" *ngIf="prContent.release_status == Constant.pcReleaseStatusStop">
      <div class="release_status stopped">
        非公開
      </div>
    </div>
  </ng-container>
  <div *ngIf="!viewMode" class="buttons">
    <button mat-raised-button type="button" (click)="onEdit()">編集</button>
    <button *ngIf="prContent.release_status != Constant.pcReleaseStatusRelease" mat-raised-button type="button" (click)="onRelease(true)">
      公開中にする
    </button>
    <button *ngIf="prContent.release_status == Constant.pcReleaseStatusRelease" mat-raised-button type="button" (click)="onRelease(false)">
      非公開にする
    </button>
  </div>
  <div mat-icon-button (click)="onClose()">
    <img src="assets/common/close_button.png" srcset="
        assets/common/close_button.png    1x,
        assets/common/close_button@2x.png 2x,
        assets/common/close_button@3x.svg 3x
      " class="close-button" />
  </div>
</div>
<mat-dialog-content>
  <div class="spinner" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
  </div>
  <ng-container *ngIf="!showSpinner">
    <div class="content" >
      <ng-container *ngIf="prContent.image_url != Constant.empty">
        <div class="generalfont bold">
          <img src="assets/notice/mark_gray.png" srcset="
              assets/notice/mark_gray.png    1x,
              assets/notice/mark_gray@2x.png 2x,
              assets/notice/mark_gray@3x.svg 3x
            " /> 採用PRコンテンツ メイン画像
        </div>
        <div class="image-container">
            <img class="primage" src="{{ prContent.image_url }}" />
            <div class="image-back" [style.background-image]="'url(' + prContent.image_url + ')'"></div>
        </div>
      </ng-container>
      <div class="titlearea">
        <div class="generalfont bold">
          <img src="assets/notice/mark_gray.png" srcset="
              assets/notice/mark_gray.png    1x,
              assets/notice/mark_gray@2x.png 2x,
              assets/notice/mark_gray@3x.svg 3x
            " /> タイトル
        </div>
        <div class="value pre-line">{{ prContent.title }}</div>
      </div>
      <div class="contentsmargin">
        <div class="generalfont bold">
          <img src="assets/notice/mark_gray.png" srcset="
              assets/notice/mark_gray.png    1x,
              assets/notice/mark_gray@2x.png 2x,
              assets/notice/mark_gray@3x.svg 3x
            " /> 本文
        </div>
        <div class="value pre-line quill-area" >
          <ng-container *ngIf="description">
            <quill-view [content]="description"
              preserveWhitespace="true"
              style ="border-width: 0;"
              [class.hidden]="!loadFlg"
              (onEditorCreated)="onEditorCreated($event)"></quill-view>
          </ng-container>
          <div class="spinner" *ngIf="!loadFlg || !description">
            <mat-spinner></mat-spinner>
          </div>
        </div>
      </div>
      <div class="contentsmargin">
        <div class="generalfont bold">
          <img src="assets/notice/mark_gray.png" srcset="
              assets/notice/mark_gray.png    1x,
              assets/notice/mark_gray@2x.png 2x,
              assets/notice/mark_gray@3x.svg 3x
            " /> カテゴリー
        </div>
        <div class="value">{{ prContent.category }}</div>
      </div>
      <div class="contentsmargin">
        <div class="generalfont bold">
          <img src="assets/notice/mark_gray.png" srcset="
              assets/notice/mark_gray.png    1x,
              assets/notice/mark_gray@2x.png 2x,
              assets/notice/mark_gray@3x.svg 3x
            " /> #タグ
        </div>
        <div *ngIf="prContent.tag1_text" class="value">{{ prContent.tag1_text }}</div>
        <div *ngIf="prContent.tag2_text" class="value">{{ prContent.tag2_text }}</div>
        <div *ngIf="prContent.tag3_text" class="value">{{ prContent.tag3_text }}</div>
        <div *ngIf="prContent.tag4_text" class="value">{{ prContent.tag4_text }}</div>
        <div *ngIf="prContent.tag5_text" class="value">{{ prContent.tag5_text }}</div>
        <div *ngIf="!prContent.tag1_text && !prContent.tag2_text && !prContent.tag3_text && !prContent.tag4_text && !prContent.tag5_text" class="value">
          {{ Constant.textNotRegist }}
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <ng-container *ngIf="prContent.release_status != Constant.pcReleaseStatusNoRelease">
      <div class="flexarea actionarea">
        <div class="generalfont">
          <span class="actionlabel">閲覧数</span>
          <span class="actionvalue">{{ prContent.more_cnt }}</span>
        </div>
        <div class="generalfont">
          <span class="actionlabel">いいね</span>
          <span class="actionvalue">{{ prContent.good_cnt }}</span>
        </div>
      </div>
    </ng-container>
    <div class="footer">
      <div *ngIf="prContent.release_status != Constant.pcReleaseStatusNoRelease" class="flexarea generalfont release">
        <div class="labelarea">初公開</div>
        <div class="byarea">{{ prContent.released_by }}</div>
        <div class="datearea">{{ prContent.release_date }}</div>
      </div>
      <div class="flexarea generalfont last_modify">
        <div class="labelarea">最終編集</div>
        <div class="byarea">{{ prContent.updated_by }}</div>
        <div class="datearea">{{ prContent.updated_at }}</div>
      </div>
      <div class="flexarea generalfont created">
        <div class="labelarea">作成</div>
        <div class="byarea">{{ prContent.created_by }}</div>
        <div class="datearea">{{ prContent.created_at }}</div>
      </div>
    </div>
  </ng-container>
</mat-dialog-content>
