import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Auth } from '@aws-amplify/auth';
import { AuthService } from '../auth/auth.service';
import { GeneralYesNoDialogComponent } from '../dialog/general-yes-no-dialog/general-yes-no-dialog.component';
import { CommonUtil } from '../util/common-util';
import { ToastUtil } from '../util/toast-util';
import { Constant } from './../constant';
import { PrcontentCategoryEditDialogComponent } from './../dialog/prcontent-category-edit-dialog/prcontent-category-edit-dialog.component';

@Component({
  selector: 'app-pr-contents-category',
  templateUrl: './pr-contents-category.component.html',
  styleUrls: ['./pr-contents-category.component.css']
})
export class PrContentsCategoryComponent implements OnInit {
  public readonly Constant = Constant;
  public categories = null;
  public showSpinner = true;
  public categoryMaxLen = 10;
  public urlBase;
  private orderMin;
  private apiPath = '/prcontent/category';

  constructor(
    private auth: AuthService,
    private title: Title,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
    private router: Router,
    private dialog: MatDialog,
  ) {
    this.title.setTitle(Constant.pageTitlePrContentCategory + Constant.pageTitleCommon);
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    if (!this.commonUtil.checkOnline()) {
      return;
    }
    this.getCategoryList();
  }

  // 閉じる
  onClose() {
    if (!this.commonUtil.checkOnline()) {
      return;
    }
    this.router.navigate(['pr_contents']);
  }

  // 新規作成・編集
  onEdit(index?) {
    if (!this.commonUtil.checkOnline()) {
      return;
    }

    let categoryData;
    let type;
    if (index != null) {
      categoryData = this.categories[index];
      type = Constant.modeEdit;
    } else {
      categoryData = {
        image_url: Constant.empty,
        title: Constant.empty,
        description: Constant.empty,
        url: Constant.empty,
        order_no: this.orderMin,
      };
      type = Constant.modeAdd;
    }

    const dialogRef = this.dialog.open(PrcontentCategoryEditDialogComponent, {
      width: Constant.questionEditDialogWidth,
      height: Constant.jobOfferDialogHeight,
      autoFocus: false,
      disableClose: true,
      data: {
        type: type,
        category: categoryData,
        urlBase: this.urlBase
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== Constant.empty) {
        this.getCategoryList();
      }
    });
  }

  // 削除
  onDel(id) {
    if (!this.commonUtil.checkOnline()) {
      return;
    }

    const dialogRef = this.dialog.open(GeneralYesNoDialogComponent, {
      width: Constant.dialogWidth,
      autoFocus: false,
      data: { msg: Constant.pcMsgComfirmDelCategory }
    });
    dialogRef.afterClosed().subscribe(isOK => {
      if (isOK) {
        // 削除のAPI呼び出し
        this.delCategory(id);
      }
    });
  }

  // URLをコピー
  onUrlCopy(index: string) {
    let element: HTMLElement;
    element = document.getElementById('url-code-' + index);

    if (element) {
      const temp = document.createElement('div');
      temp.appendChild(document.createElement('pre')).textContent = element['value'];
      document.body.appendChild(temp);
      document.getSelection().selectAllChildren(temp);
      const succeeded = document.execCommand('copy');

      if (succeeded) {
        // コピー成功
        this.toastUtil.clearAllShowingToast();
        this.toastUtil.showInformationToast('', Constant.msgCopySuccess, Constant.toastShowMiliSec);
      } else {
        // コピー失敗
        this.toastUtil.clearAllShowingToast();
        this.toastUtil.showErrorToast('', Constant.msgCopyError, Constant.toastShowMiliSec);
      }
      document.body.removeChild(temp);
    }
  }

  /* API */
  // 一覧取得
  private getCategoryList() {
    if (!this.commonUtil.isOnline()) {
      this.showSpinner = false;
      return;
    }

    // ぐるぐる表示
    this.showSpinner = true;
    this.categories = null;

    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);

      this.commonUtil
        .apiGet(this.apiPath, options)
        .then(res => {
          this.categories = res.data.data;
          this.orderMin = res.data.order_min;
          this.urlBase = res.data.url_base;
          this.showSpinner = false;
        })
        .catch(err => {
          this.showSpinner = false;
          this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
        });
    });


  }

  // 削除のAPI呼び出し
  private delCategory(id) {
    if (!this.commonUtil.checkOnline()) {
      return;
    }

    this.showSpinner = true;
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      options['body'] = {
        id: id
      };

      this.commonUtil
        .apiDel(this.apiPath, options)
        .then(res => {
          this.toastUtil.showInformationToast(Constant.empty, Constant.msgNoticeDel, Constant.toastShowMiliSec);
          this.getCategoryList();
        })
        .catch(err => {
          this.toastUtil.showErrorToast(Constant.empty, Constant.msgNoticeDelFailed2, Constant.toastShowMiliSec);
          this.getCategoryList();
        });
    });
  }
}
