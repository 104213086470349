<mat-dialog-content>
  <div class="content prcategory">
    <form [formGroup]="form">
      <div class="input-container">
        <div class="label">
          <img src="assets/notice/mark_blue.png" srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            " />
          <span class="font-bold"> 画像</span><span class="required">{{ Constant.prefixRequire }}</span>
          <span class="gray-text">{{ Constant.prefixFormatPngJpg }}</span>
        </div>
        <div class="image-container">
          <div *ngIf="categoryImagePath == Constant.empty" class="noimage">
            <span>No Image</span>
          </div>
          <div class="image-back-area">
            <img *ngIf="categoryImagePath != Constant.empty" class="primage" [src]="categoryImagePath" />
            <div *ngIf="categoryImagePath != Constant.empty" class="image-back" [style.background-image]="'url(' + categoryImagePath + ')'"></div>
          </div>
          <div class="buttons">
            <div mat-icon-button (click)="imgFileInput.click()">
              <img src="assets/joboffer/image_upload.png" srcset="
                  assets/joboffer/image_upload.png    1x,
                  assets/joboffer/image_upload@2x.png 2x,
                  assets/joboffer/image_upload@3x.svg 3x
                " />
              <input hidden type="file" #imgFileInput accept="image/png,image/jpeg" (change)="onChangeImage($event)" />
            </div>
            <div mat-icon-button (click)="onImageDelete()">
              <img src="assets/joboffer/image_delete.png" srcset="
                  assets/joboffer/image_delete.png    1x,
                  assets/joboffer/image_delete@2x.png 2x,
                  assets/joboffer/image_delete@3x.svg 3x
                " />
            </div>
          </div>
        </div>
      </div>
      <div class="input-container">
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />
          <span class="font-bold"> タイトル</span><span class="required">{{ Constant.prefixRequire }}</span>
        </div>
        <div class="title">
          <div class="label-count" [class.length-red]="title.value.length>maxLenTitle">{{ title.value.length }}/{{ maxLenTitle }}</div>
          <input
            class="input-text title"
            formControlName="title"
            #title
            type="text"
          />
        </div>
      </div>
      <div class="input-container">
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />
          <span class="font-bold"> 説明</span>
          <span class="gray-text"> ※改行不可</span>
        </div>
        <div>
          <div class="label-count" [class.length-red]="description.value.length>maxLenDesc">{{ description.value.length }}/{{ maxLenDesc }}</div>
          <textarea
            class="textarea"
            formControlName="description"
            #description
            (keydown)="onKeyDown($event)"
            (input)="onInput()"
            rows="3"
          ></textarea>
        </div>
      </div>

      <div class="input-container">
        <div class="label">
          <img *ngIf="category.id"
            src="assets/notice/mark_gray.png"
            srcset="
              assets/notice/mark_gray.png    1x,
              assets/notice/mark_gray@2x.png 2x,
              assets/notice/mark_gray@3x.svg 3x
            "
          />
          <img *ngIf="!category.id"
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />
          <span class="font-bold" [class.bold-color]="category.id"> URL</span><span [class.font-color]="category.id">（作成後に変更できません）</span><span class="required" [class.font-color]="category.id">{{ Constant.prefixRequire }}</span><span class="gray-text" [class.font-color]="category.id">※半角の英小文字・数字</span>
        </div>
        <div class="flex-url indent">
          <ng-container *ngIf="!category.id">
            <div class="base-url">
              {{ urlBase }}
            </div>
            <div class="add-url">
              <div class="label-count" [class.length-red]="url.value.length>maxLenUrl">{{ url.value.length }}/{{ maxLenUrl }}</div>
              <input
                class="input-url"
                formControlName="url"
                #url
                type="text"
              />
            </div>
          </ng-container>
          <ng-container *ngIf="category.id">
            <div class="gray-url">{{ urlBase }}{{ form.controls.url.value }}</div>
          </ng-container>
        </div>
        <mat-error *ngIf="form.controls.url.invalid && form.controls.url.dirty" class="indent">正しい形式で入力してください。</mat-error>
      </div>

      <div class="input-container">
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x"/>
          <span class="font-bold"> 表示順</span>
        </div>
        <div class="indent margintop">
          <mat-form-field class="input-select order_no no-line">
            <mat-select formControlName="order_no" panelWidth="">
              <ng-container *ngFor="let num of orderNum; let n = index">
                <mat-option [value]="n+1">
                  {{ n+1 }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          他のカテゴリーと順番が重複した場合、作成が新しい方を上位表示します。
        </div>
      </div>

    </form>
  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="center">
  <button mat-raised-button mat-dialog-close>キャンセル</button>
  <button mat-raised-button (click)="onSave()" [disabled]="form.invalid || isSaving || !categoryImagePath" class="yes-btn">
    保存
  </button>
  <mat-spinner diameter="28" *ngIf="isSaving" class="spinner"></mat-spinner>
</mat-dialog-actions>
