<mat-dialog-content>
  <div class="content">
    <div class="header">
      <div class="title">求人一覧ページ 登録</div>
      <div class="description">こちらで登録した情報は、求人一覧ページに表示されます。</div>
    </div>
    <div class="spinner center" *ngIf="showSpinner">
      <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!showSpinner">
      <div class="coverimage-container">
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />
          メイン画像<span class="normal"> ※png/jpg</span>
        </div>
        <div class="image">
          <div *ngIf="!image_url" class="noimage">No Image</div>
          <div class="image-back-area" *ngIf="image_url">
            <img class="coverimage" [src]="image_url" />
            <div class="image-back" [style.background-image]="'url(' + image_url + ')'"></div>
          </div>
          <div class="buttons">
            <div mat-icon-button (click)="imgFileInput.click()">
              <img
                src="assets/joboffer/image_upload.png"
                srcset="
                  assets/joboffer/image_upload.png    1x,
                  assets/joboffer/image_upload@2x.png 2x,
                  assets/joboffer/image_upload@3x.svg 3x
                "
              />
              <input
                hidden
                type="file"
                #imgFileInput
                accept="image/png,image/jpeg"
                (change)="onChangeCoverImage($event)"
              />
            </div>
            <div mat-icon-button (click)="onImageDelete()">
              <img
                src="assets/joboffer/image_delete.png"
                srcset="
                  assets/joboffer/image_delete.png    1x,
                  assets/joboffer/image_delete@2x.png 2x,
                  assets/joboffer/image_delete@3x.svg 3x
                "
              />
            </div>
          </div>
        </div>
      </div>
      <div class="description-container">
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          /> 本文<span class="normal"> ※画像にリンク設定はできません</span>
        </div>
        <app-quill-common *ngIf="textData" [textData]="textData" (closeDialog)="onCloseDialog()" #quill></app-quill-common>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="center">
  <button mat-raised-button (click)="onCancel()">キャンセル</button>
  <button mat-raised-button (click)="onSave()" [disabled]="checkSaveBtn()" class="yes-btn">保存</button>
  <mat-spinner diameter="28" *ngIf="isSaving" class="spinner"></mat-spinner>
</mat-dialog-actions>
