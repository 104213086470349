<mat-dialog-content>
  <h2>CSVファイル アップロード（新規取込）</h2>
  <div class="content">
    <div class="spinner" *ngIf="showSpinner">
      <mat-spinner></mat-spinner>
    </div>
    <form [formGroup]="form" *ngIf="!showSpinner && form">
      <div class="input-container">
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />
          <span class="font-bold"> ファイル</span>　※CSV形式のみ、容量10Mまで<span class="required">{{ Constant.prefixRequire }}</span>
        </div>
        <div class="file-container" [class.white]="fileName">
          <div *ngIf="!fileName" class="file-no">
            <span>No File</span>
          </div>
          <div *ngIf="fileName" class="file">
            <span><a (click)="onFileDownload()">{{ fileName }}</a></span>
          </div>
          <div class="buttons">
            <div mat-icon-button (click)="fileInput.click()">
              <img src="assets/joboffer/image_upload.png" srcset="
                  assets/joboffer/image_upload.png    1x,
                  assets/joboffer/image_upload@2x.png 2x,
                  assets/joboffer/image_upload@3x.svg 3x
                " />
              <input hidden type="file" #fileInput accept="text/csv" (change)="onChangeFile($event)" />
            </div>
            <div mat-icon-button (click)="onFileDelete()">
              <img src="assets/joboffer/image_delete.png" srcset="
                  assets/joboffer/image_delete.png    1x,
                  assets/joboffer/image_delete@2x.png 2x,
                  assets/joboffer/image_delete@3x.svg 3x
                " />
            </div>
          </div>
        </div>
      </div>
      <div class="input-container">
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />
          <span class="font-bold"> CSVファイル出力元</span>
          <span class="required">{{ Constant.prefixRequire }}</span><br>
          <span class="label-desc">※データ整形のために必要な情報です。タレントのデータには登録されません。</span>
        </div>
        <div>
          <div class="label-count" [class.length-red]="file_from.value.length>maxLenFileFrom">{{ file_from.value.length }}/{{ maxLenFileFrom }}</div>
          <input
            class="input-text"
            formControlName="file_from"
            #file_from
            placeholder="採用媒体や管理ツールなどのサービス名をご記入ください。"
            type="text"
          />
        </div>
      </div>

      <div class="input-container">
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />
          <span class="font-bold"> タレント管理タグ</span>
        </div>
        <div class="accordion-container">
          <div class="trigger-container trigger-container-example" (click)="onClickAccordion('talenttag')">
            <div class="title">
              タレントに一括付与するものを選択してください。　
            </div>
            <div id="talenttag-open" class="button open-display">開く</div>
            <div id="talenttag-close" class="button close-display">閉じる</div>
          </div>
          <div id="talenttag-accordion" class="accordion talenttagaccordion-height">
            <div class="info">
              <div *ngIf="tagData.talenttag.length == 0">
                タレント管理タグがまだ作成されていません。
              </div>
              <ul class="tag-list talenttag" *ngIf="tagData.talenttag.length > 0">
                <ng-container *ngFor="let tag of tagData.talenttag; let i = index">
                  <li>
                    <mat-checkbox color="primary" [formControlName]="'talenttag_'+tag.id">{{ tag.title }}</mat-checkbox>
                  </li>
                </ng-container>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="input-container">
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />
          <span class="font-bold"> 接点・経路</span>
        </div>
        <div class="accordion-container">
          <div class="trigger-container trigger-container-example" (click)="onClickAccordion('contacttag')">
            <div class="title">
              タレントに一括付与するものを選択してください。　
            </div>
            <div id="contacttag-open" class="button open-display">開く</div>
            <div id="contacttag-close" class="button close-display">閉じる</div>
          </div>
          <div id="contacttag-accordion" class="accordion contacttagaccordion-height">
            <div class="info">
              <div *ngIf="tagData.contacttag.length == 0">
                接点・経路がまだ作成されていません。
              </div>
              <ul class="tag-list" *ngIf="tagData.contacttag.length > 0">
                <ng-container *ngFor="let tag of tagData.contacttag; let i = index">
                  <li>
                    <mat-checkbox color="primary" [formControlName]="'contacttag_'+tag.id" #tagcheck (change)="onContactCheck($event.checked, tag.id)">{{ tag.title }}<ng-container *ngIf="tag.inuse==0">（停止中）</ng-container></mat-checkbox>
                    <div class="date-picker" *ngIf="tagcheck.checked">
                      <mat-form-field class="input-date" (click)="picker.open()" floatLabel="never">
                        <input matInput [matDatepicker]="picker" [min]="dateMin" [max]="dateMax" [formControlName]="'contactdate_'+tag.id" readonly
                        placeholder="登録日 *必須">
                        <mat-datepicker-toggle matSuffix [for]="picker" class="date-pick-icon"></mat-datepicker-toggle>
                        <mat-datepicker #picker disabled="false"></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </li>
                </ng-container>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="center">
  <button mat-raised-button mat-dialog-close>キャンセル</button>
  <button mat-raised-button (click)="onSend()" class="yes-btn" [disabled]="disabledSaveBtn()">送信</button>
  <mat-spinner diameter="28" *ngIf="isSaving" class="spinner"></mat-spinner>
</mat-dialog-actions>
