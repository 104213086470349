import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Constant } from './../../constant';
import { ToastUtil } from '../../util/toast-util';
import { Auth } from '@aws-amplify/auth';
import { CommonUtil } from '../../util/common-util';
import { AuthService } from '../../auth/auth.service';
import { ImageUtil } from '../../util/image-util';
import { environment } from './../../../environments/environment';
import { QuillCommonComponent } from './../../quill-common/quill-common.component';
import { GeneralYesNoDialogComponent } from '../general-yes-no-dialog/general-yes-no-dialog.component';

@Component({
  selector: 'app-joboffer-basic-dialog',
  templateUrl: './joboffer-base-dialog.component.html',
  styleUrls: ['./joboffer-base-dialog.component.css']
})
export class JobOfferBaseDialogComponent implements OnInit {
  public baseInfo;
  private coverImageData;
  public apiPath = '/joboffer/base';
  public image_url;
  public readonly Constant = Constant;
  public showSpinner = true;
  public isSaving = false;
  public textData

  @ViewChild('imgFileInput')
  private imgFileInput: ElementRef;
  @ViewChild('quill')
  private quillComponent: QuillCommonComponent;

  constructor(
    public dialogRef: MatDialogRef<JobOfferBaseDialogComponent>,
    private toastUtil: ToastUtil,
    private commonUtil: CommonUtil,
    private auth: AuthService,
    private imageUtil: ImageUtil,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.getBaseInfo()
  }

  // 画像選択
  onChangeCoverImage(evt) {
    const file = evt.target.files[0];
    const fileReader = new FileReader();
    fileReader.onload = function() {
      this.image_url = fileReader.result;
      this.coverImageData = file;
    }.bind(this);

    fileReader.readAsDataURL(file);
  }

  // 削除
  onImageDelete() {
    this.baseInfo.image_path = '';
    this.image_url = '';
    this.coverImageData = null;
    this.imgFileInput.nativeElement.value = '';
  }

  // 保存ボタンdisabled
  checkSaveBtn() {
    if (this.showSpinner || this.isSaving) {
      return true
    } else if (!this.quillComponent) {
      return true
    } else if (this.quillComponent.getError()) {
      return true
    }

    return false
  }

  // 保存
  onSave() {
    this.isSaving = true;
    this.toastUtil.clearAllShowingToast();

    // エディタ画像数チェック
    const imgCnt = this.quillComponent.getImgCnt()
    if (imgCnt > 50) {
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgErrorSaveEditorImg, Constant.toastShowMiliSec);
      this.isSaving = false;
      return;
    }

    // エディタ本文取得
    this.baseInfo.description = this.quillComponent.getContent()

    if (!this.coverImageData) {
      // 画像が選択されていない場合はこの時点で登録APIを実行
      this.dataPost();
      return;
    }
    this.uploadImageData();
  }

  // キャンセル
  onCancel() {
    const dialogRef = this.dialog.open(GeneralYesNoDialogComponent, {
      width: Constant.dialogWidth,
      autoFocus: false,
      data: {msg: Constant.joMsgConfirmEditCancel2, check: false }
    });
    dialogRef.afterClosed().subscribe(isOK => {
      if (isOK) {
        this.dialogRef.close('');
      }
    });
  }

  // ダイアログを閉じる（エディタより起動)
  onCloseDialog() {
    this.dialogRef.close()
  }

  // トースト表示
  private showToast(msg, isSuccess) {
    if (isSuccess) {
      this.toastUtil.showInformationToast('', msg, Constant.toastShowMiliSec);
    } else {
      this.toastUtil.showErrorToast('', msg, Constant.toastShowMiliSec);
    }
  }

  // 基本情報を取得する
  private getBaseInfo() {
    if (!this.commonUtil.isOnline()) {
      this.showSpinner = false;
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
      this.dialogRef.close();
      return;
    }

    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);

      this.commonUtil
        .apiGet(this.apiPath, options)
        .then(res => {
          this.getImageUrl();
          if (res.data) {
            this.baseInfo = res.data;
          } else {
            this.baseInfo = {
              'description': '',
              'image_path': ''
            }
          }

          // エディタ反映を遅らせる
          setTimeout(() => {
            this.textData = {
              text: this.baseInfo.description,
              type: Constant.editorTypeJobBase
            }
          }, 500);
          this.commonUtil.debug().log(this.baseInfo);
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
          this.showSpinner = false;
          this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
          this.dialogRef.close();
        });
    });
  }

  // 画像URLを取得する
  private getImageUrl() {
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      const imageUrlApiPath = this.apiPath + '/imageurl';
      this.commonUtil
        .apiGet(imageUrlApiPath, options)
        .then(res => {
          this.image_url = res.data.url;
          this.showSpinner = false;
          this.commonUtil.debug().log(this.image_url);
        })
        .catch(err => {
          this.showSpinner = false;
          this.commonUtil.debug().log(err);
          this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
          this.dialogRef.close();
        });
    });
  }

  // データ登録APIを実施
  private dataPost() {
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionJobOfferCommonInfoSave);
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.showToast(Constant.msgNetworkError, false);
      this.isSaving = false;
      return;
    }
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      options['body'] = this.baseInfo;

      this.commonUtil
        .apiPost(this.apiPath, options)
        .then(res => {
          if (res.status === Constant.OK) {
            this.showToast(Constant.msgResultSaveSuccess, true);
            this.dialogRef.close(res);
          }
          this.isSaving = false;
        })
        .catch(err => {
          this.isSaving = false;
          this.commonUtil.debug().log(err);
          this.showToast(Constant.msgSaveFailedRetry, false);
        });
    });
  }

  // 画像をS3にアップロードする
  private uploadImageData() {
    // 画像をアップロード
    const maxHeight = Constant.uploadImageHeight;
    const maxWidth = Constant.uploadImageWidth;
    const config = environment.amplify.Storage.offerCoverimage;

    this.imageUtil
      .uploadImageDataToS3(this.coverImageData, maxHeight, maxWidth, config)
      .then(image_path => {
        this.baseInfo.image_path = image_path;
        this.dataPost();
      })
      .catch(err => {
        this.showToast(Constant.msgSaveFailedRetry, false);
        this.isSaving = false;
      });
  }
}
